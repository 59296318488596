// ** Zona para inserir a descrição do vídeo
import React from "react";

import Box from "@mui/material/Box";

function LessonTitle({ chapterName, lessonName, activeChapterIndex }) {
  return (
    <Box sx={{ p: 4, pb: 1 }}>
      <h6 className="lesson-title-chapter-index">MÓDULO {activeChapterIndex + 1}</h6>
      <h2 className="lesson-title-chapter" style={{ marginTop: 0 }}>
        {chapterName}
      </h2>
      <h1 className="lesson-title-lesson" style={{ marginTop: 0 }}>
        {lessonName}
      </h1>
    </Box>
  );
}

export default LessonTitle;
