// ** React Imports
import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
// ** MUI Components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

// ** Third Party Imports
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SalesParkLogo from "src/@core/components/salespark/SalesParkLogo";
import VersionSmall from "src/@core/components/salespark/VersionSmall";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";
import { useSettings } from "src/@core/hooks/useSettings";

// ** Layout Import
import BlankLayoutV2 from "src/@core/layouts/BlankLayoutV2";

// ** Demo Imports
import FooterIllustrationsV2 from "src/views/pages/auth/FooterIllustrationsV2";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(4).required(),
});

const LoginPage = () => {
  // ** State
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  // ** Hooks
  const auth = useAuth();
  const theme = useTheme();

  const { settings } = useSettings();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const parseError = (message) => {
    if (message === "email must be a valid email") {
      return "Endereço de e-mail inválido";
    }
    if (message === "email is a required field") {
      return "Endereço de e-mail é um campo obrigatório";
    }

    if (message === "password is a required field") {
      return "Senha é um campo obrigatório";
    }

    if (message === "password must be at least 4 characters") {
      return "A Senha tem de ter pelo menos 4 caracteres";
    }

    return message;
  };

  const parseLoginError = (reason) => {
    switch (reason) {
      case "record_not_found":
        setError("email", { type: "manual", message: "Não foi possível encontrar o registo." });
        break;
      case "password_does_not_match":
        setError("password", { type: "manual", message: "Endereço de e-mail ou senha inválido." });
        break;
      case "user_blocked":
        setError("email", { type: "manual", message: "Conta inativa. Entra em contato com o suporte." });
        break;
      case "user_not_active":
        setError("email", { type: "manual", message: "Utilizador inativo. Entra em contato com o suporte." });
        break;
      default:
        setError("email", { type: "manual", message: "Oops, algo correu mal..." });
        break;
    }
  };

  const onSubmit = (data) => {
    const { email, password } = data;

    auth.login({ email: email.toLowerCase(), password }, (a) => {
      parseLoginError(a?.response?.data?.reason);
    });
  };

  return (
    <BlankLayoutV2>
      <Box className="content-center">
        <Card sx={{ zIndex: 1, width: "100%", maxWidth: "520px" }}>
          <CardContent sx={{ p: (theme) => `${theme.spacing(6.5, 7, 6.5)} !important` }}>
            <SalesParkLogo />
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
                Bem-Vindo 👋🏻
              </Typography>
              <Typography variant="body2">Inicia a tua conta, e que comece a aventura </Typography>
            </Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      autoFocus
                      label="Endereço de e-mail"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e.target.value.replace(/\s/g, ""));
                      }}
                      error={Boolean(errors.email)}
                      placeholder="O teu endereço de e-mail"
                    />
                  )}
                />
                {errors.email && (
                  <FormHelperText sx={{ color: "error.main" }}>{parseError(errors?.email?.message)}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="auth-login-v2-password" error={Boolean(errors.password)}>
                  Senha
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label="Senha"
                      onChange={onChange}
                      id="auth-login-v2-password"
                      error={Boolean(errors.password)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: "error.main" }} id="">
                    {parseError(errors?.password.message)}
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mb: 4, display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "right" }}>
                {/* <FormControlLabel
                label='Lembrar-me'
                control={<Checkbox />}
                sx={{ '& .MuiFormControlLabel-label': { color: 'text.primary' } }}
              /> */}
                <Link component={RouterLink} to="/forgot-password">
                  <Typography variant="body2" sx={{ color: "primary.main" }}>
                    <small>Esqueci-me da senha</small>
                  </Typography>
                </Link>
              </Box>
              <Button fullWidth size="large" type="submit" variant="contained" sx={{ mb: 7 }}>
                Entrar
              </Button>
            </form>
          </CardContent>
          <VersionSmall />
        </Card>
        <FooterIllustrationsV2 />
      </Box>
    </BlankLayoutV2>
  );
};

export default LoginPage;
